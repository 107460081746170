// Imports
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import WeatherInfo from './WeatherInfo';
import jsPDF from 'jspdf';
import { LeftCircleOutlined, RightCircleOutlined, PrinterOutlined, AreaChartOutlined, DatabaseOutlined, BarChartOutlined } from '@ant-design/icons';
import { Divider, Modal, DatePicker } from 'antd';
import dayjs from 'dayjs';
import WeeklySummaryModal from './WeeklySummaryModal';

const LATITUDE = 44.854031;
const LONGITUDE = -93.460167;
const API_KEY = '0de005293c6af251d2b471f3a70afbb6';

const { RangePicker } = DatePicker;

// insertTime Function
const insertTime = function() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const currentTime = `${formattedHours}:${formattedMinutes} ${ampm}\t`;
    
    const editor = this.quill;
    const range = editor.getSelection();
    if (range) {
        // Insert a new line if we're not at the start of the document
        if (range.index > 0) {
            editor.insertText(range.index, '\n');
        }
        // Insert indentation, bullet point, and time
        editor.insertText(range.index, `     • ${currentTime}`);
        // Move cursor to the end of the inserted text
        editor.setSelection(range.index + currentTime.length + 7); // +7 for the indentation, bullet, and space
    }
};

// Adding a custom Quill module
Quill.register('modules/insertTime', insertTime);

// MainPage funtion
function MainPage() {
    const navigate = useNavigate();

    const getCurrentTime = () => {
        const now = new Date();
        return now.toTimeString().substring(0, 5);
    };

    const isFutureDate = (dateString) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const checkDate = new Date(dateString);
        return checkDate > today;
    };

    const formatDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const adjustedDate = new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
        return adjustedDate.toLocaleDateString(undefined, options);
    };

    // Modal Dialog Processing
    const [isModalVisible, setIsModalVisible] = useState(false);  // Controls modal visibility
    const [printRange, setPrintRange] = useState([null, null]);   // Holds the selected date range

    // Function to show the modal when Print is clicked
    const handlePrintClick = () => {
        setIsModalVisible(true);
    };

    // Function to handle the modal submission (OK button)
    const handleModalOk = () => {
        if (printRange[0] && printRange[1]) {
            const startDate = dayjs(printRange[0]).format('YYYY-MM-DD');
            const endDate = dayjs(printRange[1]).format('YYYY-MM-DD');
            handlePrint(startDate, endDate);
        }
        setIsModalVisible(false);
    };

    const handlePrint = async (startDate, endDate) => {
        try {
            const response = await axios.get(`https://omniscient21.com:5003/print-data/${startDate}/${endDate}`);
            console.log('Print data received:', response.data);
            generatePDF(response.data);
        } catch (error) {
            console.error('Error fetching print data:', error);
        }
    };

    // Function to close the modal without submitting
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    // Function to store the selected date range
    const handleRangeChange = (dates) => {
        setPrintRange(dates);
    };

    // getDefaultData Function
    const getDefaultData = () => ({
        arise: null,
        depression: 0,
        headache: 0,
        bedtime: null,
        notes: '',
        dietCoke: [{ time: null }],
        milk: [{ time: null }],
        foodConsumed: '',
        cheatDay: null,
        mood: [{ feeling: 0, flag: false, redZone: false, time: getCurrentTime(), notes: '' }],
        exercise: { walkingMiles: 0, ruckWeight: 0, resistance: false },
        medication: [{ medication: '', quantity: '', time: null }],
        weather: null,
    });
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [data, setData] = useState(getDefaultData());


    // Helper function to parse boolean values
    const parseBoolean = (value) => {
        return value === true || value === 'true' || value === 1 || value === '1' || value === 'on';
    };

    // fetchDataForDate function
    const fetchDataForDate = async (selectedDate) => {
        console.log('Fetching data for date:', selectedDate);
        try {
            const response = await axios.get(`https://omniscient21.com:5003/data/${selectedDate}`);
            console.log('Data received from server:', response.data);

            let newData = getDefaultData();

            if (response.data && response.data.length) {
                response.data.forEach(item => {
                    if (item.section === 'weather') {
                        if (!newData.weather) newData.weather = {};
                        newData.weather[item.field] = item.value;
                    } else if (item.section === 'dietCoke' || item.section === 'milk') {
                        const index = parseInt(item.field.replace('time', '')) - 1;
                        newData[item.section][index] = { time: item.value };
                    } else if (item.section === 'mood') {
                        const [subField, index] = item.field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                        const moodIndex = parseInt(index) - 1;
                        if (!newData.mood[moodIndex]) newData.mood[moodIndex] = {};
                        if (subField === 'redZone' || subField === 'flag') {
                            newData.mood[moodIndex][subField] = parseBoolean(item.value);
                        } else {
                            newData.mood[moodIndex][subField] = item.value;
                        }
                    } else if (item.section === 'medication') {
                        const [subField, index] = item.field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                        const medIndex = parseInt(index) - 1;
                        if (!newData.medication[medIndex]) newData.medication[medIndex] = {};
                        newData.medication[medIndex][subField] = item.value;
                    } else if (item.section === 'exercise') {
                        if (item.field === 'resistance') {
                            newData.exercise.resistance = parseBoolean(item.value);
                        } else {
                            newData.exercise[item.field] = item.value;
                        }
                    } else if (item.section === 'Food' && item.field === 'cheatDay') {
                        newData.cheatDay = parseBoolean(item.value);
                    } else {
                        newData[item.field] = item.value;
                    }
                });
            }

            console.log('New data structure after processing:', newData);
            return newData;
        } catch (error) {
            console.error('Error fetching data:', error);
            return getDefaultData();
        }
    };

    // fetchWeatherData Function
    const fetchWeatherData = async (selectedDate) => {
        const today = new Date().toISOString().split('T')[0];
        
        if (selectedDate < today) {
            console.log('Past date, cannot fetch accurate weather data');
            return {
                isPastDate: true,
                message: "Historical weather data is not available."
            };
        }
    
        console.log('Fetching weather data for:', selectedDate);
        try {
            const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${LATITUDE}&lon=${LONGITUDE}&appid=${API_KEY}&units=imperial`);
            const weatherData = {
                temperature: Math.round(response.data.main.temp),
                description: response.data.weather[0].description,
                sunrise: formatTime(response.data.sys.sunrise),
                sunset: formatTime(response.data.sys.sunset),
                hoursOfDaylight: calculateDaylight(response.data.sys.sunrise, response.data.sys.sunset)
            };
            
            // Save weather data to the database
            Object.entries(weatherData).forEach(([field, value]) => {
                handleFieldChange('weather', field, value);
            });
            
            return weatherData;
        } catch (error) {
            console.error("Error fetching weather data:", error);
            return null;
        }
    };

    // useEffect for fetchDataForDateWithWeather
    useEffect(() => {
        const fetchDataForDateWithWeather = async () => {
            try {
                const fetchedData = await fetchDataForDate(date);
                
                if (!fetchedData.weather || Object.keys(fetchedData.weather).length === 0) {
                    if (!isFutureDate(date)) {
                        console.log('No weather data found, fetching from API');
                        const weatherData = await fetchWeatherData(date);
                        setData({...fetchedData, weather: weatherData});
                    } else {
                        console.log('Future date, not fetching weather data');
                        setData({...fetchedData, weather: null});
                    }
                } else {
                    console.log('Weather data found in database:', fetchedData.weather);
                    setData(fetchedData);
                }
            } catch (error) {
                console.error('Error in fetchDataForDateWithWeather:', error);
                setData(getDefaultData());
            }
        };
    
        fetchDataForDateWithWeather();
    }, [date]);

    const formatTime = (timestamp) => {
        return new Date(timestamp * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const calculateDaylight = (sunrise, sunset) => {
        const daylightSeconds = sunset - sunrise;
        const hours = Math.floor(daylightSeconds / 3600);
        const minutes = Math.floor((daylightSeconds % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    // handleFieldChange
    const handleFieldChange = (section, field, value) => {
        console.log(`Handling field change for section: ${section}, field: ${field}, value: ${value}`);

        setData(prevData => {
            const newData = { ...prevData };

            if (section === 'mood') {
                const [subField, index] = field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                const moodIndex = parseInt(index) - 1;
                if (subField === 'redZone' || subField === 'flag') {
                    newData.mood[moodIndex] = { ...newData.mood[moodIndex], [subField]: value };
                } else {
                    newData.mood[moodIndex] = { ...newData.mood[moodIndex], [subField]: value };
                }
            } else if (section === 'dietCoke' || section === 'milk') {
                const index = parseInt(field.replace('time', '')) - 1;
                newData[section][index] = { ...newData[section][index], time: value };
            } else if (section === 'medication') {
                const [subField, index] = field.match(/([a-zA-Z]+)(\d+)/).slice(1);
                const medIndex = parseInt(index) - 1;
                newData.medication[medIndex] = { ...newData.medication[medIndex], [subField]: value };
            } else if (section === 'exercise') {
                newData.exercise = { ...newData.exercise, [field]: value };
            } else if (section === 'Food' && field === 'cheatDay') {
                newData.cheatDay = value;
            } else if (section === 'Food') {
                newData[field] = value;
            } else {
                newData[field] = value;
            }

            console.log('Updated data:', newData);
            return newData;
        });

        // Send updated data to the server
        sendToServer(section, field, value);
    };
    
    // Send data to server function
    const sendToServer = (section, field, value) => {
        console.log('Sending data to server:', { section, field, value, date });
        const formattedDate = new Date(date).toISOString().split('T')[0];

        const payload = { section, field, value, date: formattedDate };
        console.log('Payload being sent to server:', payload);

        axios.post('https://omniscient21.com:5003/save', payload)
            .then(response => {
                console.log('Data saved successfully:', response.data);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                console.error('Error details:', error.response?.data);
            });
    };

    // handleAddEntry Function
    const handleAddEntry = (section) => {
        setData(prevData => {
            let newData = { ...prevData };
            let newEntry;
            if (section === 'dietCoke' || section === 'milk') {
                newEntry = { time: getCurrentTime() };
                newData[section] = [...newData[section], newEntry];

                // Save the new entry to the server
                const index = newData[section].length;
                sendToServer(section, `time${index}`, newEntry.time);
            } else if (section === 'mood') {
                newEntry = { feeling: 0, flag: false, redZone: false, time: getCurrentTime(), notes: '' };
                newData.mood = [...newData.mood, newEntry];

                // Save the new entry to the server
                const index = newData.mood.length;
                Object.entries(newEntry).forEach(([key, value]) => {
                    sendToServer(section, `${key}${index}`, value);
                });
            } else if (section === 'medication') {
                newEntry = { medication: '', quantity: '', time: getCurrentTime() };
                newData.medication = [...newData.medication, newEntry];

                // Save the new entry to the server
                const index = newData.medication.length;
                Object.entries(newEntry).forEach(([key, value]) => {
                    sendToServer(section, `${key}${index}`, value);
                });
            }

            return newData;
        });
    };

    // handleRemoveEntry Function
    const handleRemoveEntry = (section, index) => {
        setData(prevData => {
            const newData = { ...prevData };
            if (newData[section].length > 1) {
                newData[section].splice(index, 1);
            }
            return newData;
        });

        const fieldsToDelete = [];
        if (section === 'mood') {
            fieldsToDelete.push(`feeling${index + 1}`, `flag${index + 1}`, `redZone${index + 1}`, `time${index + 1}`, `notes${index + 1}`);
        } else if (section === 'dietCoke' || section === 'milk') {
            fieldsToDelete.push(`time${index + 1}`);
        } else if (section === 'medication') {
            fieldsToDelete.push(`medication${index + 1}`, `quantity${index + 1}`, `time${index + 1}`);
        }

        const deleteRequests = fieldsToDelete.map(field =>
            axios.post('https://omniscient21.com:5003/delete', { section, field, date })
        );

        Promise.all(deleteRequests)
            .then(() => {
                console.log(`Successfully deleted ${section} entry at index ${index}`);
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    };
    // handleDateChange Function
    const handleDateChange = (direction) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + direction);
        setDate(newDate.toISOString().split('T')[0]);
    };

    // State variable for weekly summary page
    const [isWeeklySummaryVisible, setIsWeeklySummaryVisible] = useState(false);
    const [weeklySummaryData, setWeeklySummaryData] = useState(null);
    const [dateRange, setDateRange] = useState('');

    // fetchWeeklySummaryData function
    const fetchWeeklySummaryData = async () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

    // Calculate last Monday (or today if it's Monday)
    const monday = new Date(today);
    monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));

    // Calculate last Sunday (or today if it's Sunday)
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - dayOfWeek);

    // Calculate the number of days to count (always 7 for a full week)
    const daysToCount = 7;

    const dateRange = `${monday.toLocaleDateString()} - ${sunday.toLocaleDateString()}`;

    // Add this logging
    console.log(`Fetching data for date range: ${monday.toISOString().split('T')[0]} to ${sunday.toISOString().split('T')[0]}`);

    try {
        const response = await axios.get(`https://omniscient21.com:5003/weekly-summary/${monday.toISOString().split('T')[0]}/${sunday.toISOString().split('T')[0]}`);
        const rawData = response.data;
        console.log("Raw data received:", rawData);

        // Restructure sleep data
        const restructuredSleepData = rawData.sleep.reduce((acc, item) => {
        const date = new Date(item.date).toISOString().split('T')[0];
        if (!acc[date]) {
            acc[date] = { date, arise: null, bedtime: null, notes: '', headache: null };
        }
        if (item.arise) acc[date].arise = item.arise;
        if (item.bedtime) acc[date].bedtime = item.bedtime;
        if (item.notes) acc[date].notes = item.notes;
        if (item.headache) acc[date].headache = item.headache;
        return acc;
        }, {});

        console.log("Restructured sleep data:", restructuredSleepData);

        let totalSleepMinutes = 0;
        let validSleepDays = 0;
        let incompleteDays = false;

        Object.entries(restructuredSleepData).forEach(([date, day]) => {
            console.log(`Processing day ${date}:`, day);
            
            // Skip the current day
            if (new Date(date).toDateString() === new Date().toDateString()) {
                console.log(`  Skipping current day (${date})`);
                return;
            }

            if (day.arise && day.bedtime) {
                let [ariseHours, ariseMinutes] = day.arise.split(':').map(Number);
                let [bedHours, bedMinutes] = day.bedtime.split(':').map(Number);
                
                // Handle midnight (00:00) as 24:00
                if (bedHours === 0 && bedMinutes === 0) {
                    bedHours = 24;
                }
                
                // Adjust bedtime if it's earlier than arise time (assuming it's PM)
                if (bedHours < ariseHours || (bedHours === ariseHours && bedMinutes < ariseMinutes)) {
                    bedHours += 12;
                }

                console.log(`  Arise time: ${ariseHours}:${ariseMinutes}, Bedtime: ${bedHours}:${bedMinutes}`);
                
                // Calculate sleep duration in minutes
                let sleepMinutes = (bedHours * 60 + bedMinutes) - (ariseHours * 60 + ariseMinutes);
                
                // If sleep duration is negative, it means bedtime was after midnight
                if (sleepMinutes < 0) {
                    sleepMinutes += 24 * 60; // Add 24 hours
                }
                
                console.log(`  Sleep minutes for this day: ${sleepMinutes}`);
                totalSleepMinutes += sleepMinutes;
                validSleepDays++;
            } else {
                console.log(`  Incomplete data for day ${date}`);
                incompleteDays = true;
            }
        });

        console.log(`Total sleep minutes: ${totalSleepMinutes}`);
        console.log(`Valid sleep days: ${validSleepDays}`);

        // Convert minutes to hours and round to nearest quarter hour
        const totalSleepHours = Math.round(totalSleepMinutes / 60 * 4) / 4;
        console.log(`Total sleep hours (rounded): ${totalSleepHours}`);

        const averageSleepHours = validSleepDays > 0 ? Math.round((totalSleepMinutes / 60 / validSleepDays) * 4) / 4 : 0;

        const summaryData = {
            sleep: {
                totalHours: totalSleepHours.toFixed(2) + (incompleteDays ? '*' : ''),
                averageHours: averageSleepHours.toFixed(2),
                averageAriseTime: averageTime(Object.values(restructuredSleepData).filter(day => day.arise).map(day => day.arise)),
                averageBedtime: averageTime(Object.values(restructuredSleepData).filter(day => day.bedtime).map(day => day.bedtime)),
            },
            beverages: {
                totalDietCokes: rawData.dietCoke.reduce((sum, day) => sum + (day.count || 0), 0),
                totalMilks: rawData.milk.reduce((sum, day) => sum + (day.count || 0), 0),
                avgDietCokesPerDay: (rawData.dietCoke.reduce((sum, day) => sum + (day.count || 0), 0) / daysToCount).toFixed(2),
                avgMilksPerDay: (rawData.milk.reduce((sum, day) => sum + (day.count || 0), 0) / daysToCount).toFixed(2),
            },
            exercise: {
                totalWalkingMiles: rawData.exercise.reduce((sum, day) => sum + parseFloat(day.walkingMiles || 0), 0).toFixed(2),
                totalRuckingDays: rawData.exercise.filter(day => parseFloat(day.ruckWeight || 0) > 0).length,
                totalResistanceDays: rawData.exercise.filter(day => day.resistance === true || day.resistance === 'true').length,
            },
            mood: {
                totalFlags: rawData.mood && Array.isArray(rawData.mood) 
                    ? rawData.mood.reduce((sum, day) => sum + (day.flags || 0), 0)
                    : 0,
                totalRedZone: rawData.mood && Array.isArray(rawData.mood)
                    ? rawData.mood.reduce((sum, day) => sum + (day.redZone || 0), 0)
                    : 0
            }
        };

        console.log("Final summary data:", summaryData);

        setWeeklySummaryData(summaryData);
        setDateRange(dateRange);
        setIsWeeklySummaryVisible(true);
    } catch (error) {
        console.error('Error fetching weekly summary data:', error);
        console.error('Error details:', error.response?.data);
    }
};

const averageTime = (times) => {
    const validTimes = times.filter(time => time);
    if (validTimes.length === 0) return 'N/A';
    const totalMinutes = validTimes.reduce((sum, time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return sum + hours * 60 + minutes;
    }, 0);
    const averageMinutes = Math.round(totalMinutes / validTimes.length);
    const hours = Math.floor(averageMinutes / 60);
    const minutes = averageMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};

    // generatePDF Function
    const generatePDF = (data) => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        let yOffset = 10;
        let totalPages = Object.keys(data).length; // Get total number of pages

    // Helper function to parse HTML content
    const parseHtmlContent = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        let parsedContent = [];

        const parseNode = (node, indent = 0, listType = null) => {
            if (node.nodeType === Node.TEXT_NODE) {
                return node.textContent.trim();
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                switch (node.tagName.toLowerCase()) {
                    case 'ul':
                        listType = 'ul';
                        return Array.from(node.childNodes).map(child => parseNode(child, indent, listType)).join('\n');
                    case 'ol':
                        listType = 'ol';
                        return Array.from(node.childNodes).map((child, index) => parseNode(child, indent, listType, index + 1)).join('\n');
                    case 'li':
                        const bullet = listType === 'ul' ? '•' : `${arguments[3]}.`;
                        const content = Array.from(node.childNodes).map(child => parseNode(child, indent + 1)).join(' ').trim();
                        const indentString = ' '.repeat(indent * 4);
                        const bulletString = `${indentString}${bullet} `;
                        const contentLines = pdf.splitTextToSize(content, 180 - (indent + 1) * 4);
                        return bulletString + contentLines.join(`\n${' '.repeat(bulletString.length)}`);
                    default:
                        return Array.from(node.childNodes).map(child => parseNode(child, indent)).join(' ');
                }
            }
            return '';
        };

        const content = parseNode(doc.body);
        return content.split('\n');
    };

        // Helper function to format date
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        };

        // Helper function to add bold text
        const addBoldText = (text, x, y) => {
            pdf.setFont('helvetica', 'bold');
            pdf.text(text, x, y);
            pdf.setFont('helvetica', 'normal');
        };

        // Helper function to add page number
        const addPageNumber = (pageNum) => {
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'normal');
            const text = `Page ${pageNum} of ${totalPages}`;
            const textWidth = pdf.getTextWidth(text);
            const x = (pdf.internal.pageSize.width - textWidth) / 2;
            pdf.text(text, x, 287); // 287 mm is near the bottom of an A4 page
        };

        Object.entries(data).forEach(([dateString, dayData], pageIndex) => {
            if (pageIndex > 0) {
                pdf.addPage();
                yOffset = 10;
            }

        // Add page number at the beginning of each page
        addPageNumber(pageIndex + 1);
        yOffset = 10;

        // Add formatted date in bold
        const formattedDate = formatDate(dateString);
        pdf.setFontSize(16);
        addBoldText(formattedDate, 10, yOffset);
        yOffset += 10;

        // Function to add a section with bold headings and labels
        const addSection = (title, content) => {
            pdf.setFontSize(14);
            addBoldText(title, 10, yOffset);
            yOffset += 7;
            pdf.setFontSize(10);
            content.forEach(line => {
                if (typeof line === 'string') {
                    const [label, ...rest] = line.split(':');
                    if (rest.length > 0) {
                        addBoldText(label + ':', 15, yOffset);
                        pdf.text(rest.join(':').trim(), 15 + pdf.getTextWidth(label + ': '), yOffset);
                    } else {
                        pdf.text(line, 15, yOffset);
                    }
                    yOffset += 5;
                } else if (Array.isArray(line)) {
                    line.forEach(subline => {
                        pdf.text(subline, 15, yOffset);
                        yOffset += 5;
                    });
                }
                if (yOffset > 280) {
                    pdf.addPage();
                    yOffset = 10;
                    addPageNumber(pageIndex + 2); // Add page number to new page
                }
            });
            yOffset += 5;
        };

        // Add Weather section
        if (dayData.weather) {
            addSection('Weather', [
                `Temperature: ${dayData.weather.temperature || 'N/A'}°F`,
                `Weather: ${dayData.weather.description || 'N/A'}`,
                `Sunrise: ${dayData.weather.sunrise || 'N/A'}`,
                `Sunset: ${dayData.weather.sunset || 'N/A'}`,
                `Hours of Daylight: ${dayData.weather.hoursOfDaylight || 'N/A'}`
            ]);
        }

        // Add Sleep section
        if (dayData.Sleep) {
            addSection('Sleep', [
                `Arise: ${dayData.Sleep.arise || 'Not set'}`,
                `Depression: ${dayData.Sleep.depression || 'N/A'}`,
                `Headache: ${dayData.Sleep.headache || 'N/A'}`,
                `Bedtime: ${dayData.Sleep.bedtime || 'Not set'}`,
                `Notes:`,
                ...parseHtmlContent(dayData.Sleep.notes || 'None')
            ]);
        }

            // Add Diet Coke section
            if (dayData.dietCoke) {
                const dietCokeEntries = Object.entries(dayData.dietCoke)
                    .map(([key, value]) => `${key}: ${value}`);
                addSection('Diet Coke', dietCokeEntries.length > 0 ? dietCokeEntries : ['No Diet Coke entries']);
            }

            // Add Milk section
            if (dayData.milk) {
                const milkEntries = Object.entries(dayData.milk)
                    .map(([key, value]) => `${key}: ${value}`);
                addSection('Milk', milkEntries.length > 0 ? milkEntries : ['No Milk entries']);
            }

            // Add Food section
            if (dayData.Food) {
                addSection('Food', [
                    `Cheat Day: ${dayData.Food.cheatDay ? 'Yes' : 'No'}`,
                    ...parseHtmlContent(dayData.Food.foodConsumed || 'No food recorded')
                ]);
            }

            // Add Mood section
            if (dayData.mood) {
                Object.entries(dayData.mood).forEach(([key, value], index) => {
                    if (key.startsWith('feeling')) {
                        const moodIndex = key.replace('feeling', '');
                        addSection(`Mood ${moodIndex}`, [
                            `Feeling: ${value || 'N/A'}`,
                            `Time: ${dayData.mood[`time${moodIndex}`] || 'N/A'}`,
                            `Notes:`,
                            parseHtmlContent(dayData.mood[`notes${moodIndex}`] || 'None')
                        ]);
                    }
                });
            }

            // Add Exercise section
            if (dayData.exercise) {
                addSection('Exercise', [
                    `Walking Miles: ${dayData.exercise.walkingMiles || 'N/A'}`,
                    `Ruck Weight (lbs): ${dayData.exercise.ruckWeight || 'N/A'}`,
                    `Resistance?: ${dayData.exercise.resistance ? 'Yes' : 'No'}`
                ]);
            }

            // Add Medication section
            if (dayData.medication) {
                Object.entries(dayData.medication).forEach(([key, value], index) => {
                    if (key.startsWith('medication')) {
                        const medIndex = key.replace('medication', '');
                        addSection(`Medication ${medIndex}`, [
                            `Medication: ${value || 'Not specified'}`,
                            `Qty: ${dayData.medication[`quantity${medIndex}`] || 'Not specified'}`,
                            `Time: ${dayData.medication[`time${medIndex}`] || 'Not specified'}`
                        ]);
                    }
                });
            }

        });

        pdf.save('health_tracker_report.pdf');
    };

    // Medications Picklist Values
    const medicationOptions = [
        "Dexmethyphenidate HCL ER Cap 5 Mg",
        "Methylphenidate 5 MG Tablet",
        "Methylphenidate 10 MG Tablet",
        "Methylphenidate 18 MG ER Tablet",
        "Methylphenidate 20 MG Tablet",
        "Short Acting Insulin",
        "Long Acting Insulin",
        "Excedrine Migraine Relief 250mg Caplet",
        "Aleve 220mg Caplet",
        "Vitamin D"
    ];

    const modulesWithTime = {
        toolbar: {
            container: [
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image'],
                [{ 'insertTime': 'Time' }]
            ],
            handlers: {
                insertTime: insertTime
            }
        }
    };

    const modulesWithoutTime = {
        toolbar: {
            container: [
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image']
            ]
        }
    };

    // handleViewData Function
    const handleViewData = () => {
        navigate('/view-data'); // Add navigation to ViewData component
    };

      // handleViewGraph Function
    const handleViewGraph = () => {
        navigate('/view-graph'); // Navigate to the View Graph page
    };

    // return
    return (
        <div className="container">

            {/* Header Section */}
            <div className="header-wrapper">
                <header>
                    <LeftCircleOutlined
                        onClick={() => handleDateChange(-1)}
                        className="date-change-icon"
                    />
                    <div className="date-container">
                        <div className="date-display">{formatDate(date)}</div>
                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </div>
                    <RightCircleOutlined
                        onClick={() => handleDateChange(1)}
                        className="date-change-icon"
                    />
                </header>
            </div>

            {/* Weather Section */}
            <section className="weather-section">
                <h2>Weather</h2>
                {isFutureDate(date) ? (
                    <p>Weather data not available for future dates.</p>
                ) : (
                    <WeatherInfo weatherData={data.weather} />
                )}
            </section>

            {/* Sleep Section */}
            <section>
                <h2>Sleep</h2>
                <div className="sleep-row">
                    <div className="form-group">
                        <label>Arise</label>
                        <input 
                            type="time" 
                            value={data.arise || ''} 
                            onChange={(e) => handleFieldChange('Sleep', 'arise', e.target.value)} 
                            step="900" // Add this line
                        />
                    </div>
                    <div className="form-group">
                        <label>Depression</label>
                        <input 
                            type="number" 
                            step="0.25" 
                            min="0" 
                            max="5" 
                            value={data.depression} 
                            onChange={(e) => handleFieldChange('Sleep', 'depression', e.target.value)} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Headache</label>
                        <input 
                            type="number" 
                            step="1" 
                            min="0" 
                            max="5" 
                            value={data.headache} 
                            onChange={(e) => handleFieldChange('Sleep', 'headache', e.target.value)} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Bedtime</label>
                        <input 
                            type="time" 
                            value={data.bedtime || ''} 
                            onChange={(e) => handleFieldChange('Sleep', 'bedtime', e.target.value)} 
                            step="900" // Add this line
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label>Notes</label>
                    <ReactQuill 
                        className="quill sleep-quill"
                        value={data.notes} 
                        onChange={(value) => handleFieldChange('Sleep', 'notes', value)} 
                        modules={modulesWithoutTime}
                    />
                </div>
            </section>

            {/* Beverages Section */ }
            <section>
            <h2>Beverages</h2>
            
            <div className="beverages-container">
                <div className="beverage-column">
                <h3>Diet Coke</h3>
                {data.dietCoke.map((entry, index) => (
                    <div key={index}>
                    <div className="beverage-row">
                        <div className="form-group">
                        <label>Diet Coke {index + 1}</label>
                        <input
                            type="time"
                            value={entry.time || ''}
                            onChange={(e) => handleFieldChange('dietCoke', `time${index + 1}`, e.target.value)}
                            step="900"
                            min="00:00"
                        />
                        </div>
                        <div className="beverage-buttons">
                        <button onClick={() => handleAddEntry('dietCoke')}>+</button>
                        <button 
                            onClick={() => handleRemoveEntry('dietCoke', index)} 
                            disabled={index === 0 || data.dietCoke.length === 1}
                        >
                            -
                        </button>
                        </div>
                    </div>
                    {index < data.dietCoke.length - 1 && <Divider style={{ color: '#1a9cd4' }} />}
                    </div>
                ))}
                </div>
            
                <div className="beverage-column">
                <h3>Milk</h3>
                {data.milk.map((entry, index) => (
                    <div key={index}>
                    <div className="beverage-row">
                        <div className="form-group">
                        <label>Milk {index + 1}</label>
                        <input
                            type="time"
                            value={entry.time || ''}
                            onChange={(e) => handleFieldChange('milk', `time${index + 1}`, e.target.value)}
                            step="900"
                            min="00:00"
                        />
                        </div>
                        <div className="beverage-buttons">
                        <button onClick={() => handleAddEntry('milk')}>+</button>
                        <button 
                            onClick={() => handleRemoveEntry('milk', index)} 
                            disabled={index === 0 || data.milk.length === 1}
                        >
                            -
                        </button>
                        </div>
                    </div>
                    {index < data.milk.length - 1 && <Divider style={{ color: '#1a9cd4' }} />}
                    </div>
                ))}
                </div>
            </div>
            </section>

            {/* Food Section */}
            <section>
                <div className="food-header">
                    <h2>Food</h2>
                    <div className="cheat-day-checkbox">
                        <label>
                        Cheat Day?
                            <input
                                type="checkbox"
                                checked={data.cheatDay || false}
                                onChange={(e) => handleFieldChange('Food', 'cheatDay', e.target.checked)}
                            />
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <ReactQuill 
                        className="quill food-quill"
                        value={data.foodConsumed || ''}
                        onChange={(value) => handleFieldChange('Food', 'foodConsumed', value)}
                        modules={modulesWithTime}
                    />
                </div>
            </section>

            {/* Mood Section */}
            <section>
                <h2>Mood</h2>
                {data.mood.map((entry, index) => (
                    <div className="mood-entry" key={index}>
                        <div className="mood-row">
                            <div className="form-group">
                                <label>Mood {index + 1}</label>
                                <input 
                                    type="number" 
                                    step="0.5" 
                                    min="0" 
                                    max="5" 
                                    value={entry.feeling}
                                    onChange={(e) => handleFieldChange('mood', `feeling${index + 1}`, parseFloat(e.target.value) || 0)} 
                                />
                            </div>
                            <div className="form-group">
                                <label>Time</label>
                                <input 
                                    type="time" 
                                    value={entry.time}
                                    onChange={(e) => handleFieldChange('mood', `time${index + 1}`, e.target.value)} 
                                    step="900"
                                />
                            </div>
                            <div className="form-group-flag">
                                <label>Flag:</label>
                                <input 
                                    type="checkbox" 
                                    checked={entry.flag}
                                    onChange={(e) => handleFieldChange('mood', `flag${index + 1}`, e.target.checked)} 
                                />
                            </div>
                            <div className="form-group-red-zone">
                                <label>Red Zone:</label>
                                <input 
                                    type="checkbox" 
                                    checked={entry.redZone}
                                    onChange={(e) => handleFieldChange('mood', `redZone${index + 1}`, e.target.checked)} 
                                />
                            </div>
                            <div className="mood-buttons">
                                <button onClick={() => handleAddEntry('mood')}>+</button>
                                <button 
                                    onClick={() => handleRemoveEntry('mood', index)} 
                                    disabled={index === 0 || data.mood.length === 1}
                                >
                                    -
                                </button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Notes</label>
                            <ReactQuill 
                                value={entry.notes} 
                                onChange={(value) => handleFieldChange('mood', `notes${index + 1}`, value)} 
                                modules={modulesWithoutTime}
                            />
                        </div>
                    </div>
                ))}
            </section>

            {/* Exercise Section */}             
            <section>
                <h2>Exercise</h2>
                <div className="exercise-row">
                    <div className="form-group">
                        <label>Walking Miles</label>
                        <input 
                            type="number" 
                            step="0.5" 
                            min="0" 
                            max="5" 
                            value={data.exercise.walkingMiles || 0}
                            onChange={(e) => handleFieldChange('exercise', 'walkingMiles', parseFloat(e.target.value))} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Ruck Weight (lbs)</label>
                        <input 
                            type="number" 
                            step="5" 
                            min="0" 
                            max="30" 
                            value={data.exercise.ruckWeight || 0}
                            onChange={(e) => handleFieldChange('exercise', 'ruckWeight', parseInt(e.target.value))} 
                        />
                    </div>
                    <div className="form-group">
                        <label>Resistance?</label>
                        <input 
                            type="checkbox" 
                            checked={!!data.exercise.resistance}  // Ensure it's a boolean
                            onChange={(e) => handleFieldChange('exercise', 'resistance', e.target.checked)} 
                        />
                        {console.log("Rendering checkbox with state:", !!data.exercise.resistance)}
                    </div>
                </div>
            </section>

            {/* Medication Section */}
            <section>
            <h2>Medication</h2>
            {data.medication.map((entry, index) => (
                <div
                className={`medication-row ${index % 2 === 1 ? 'alternate-row' : ''}`}
                key={index}
                >
                {/* Medication Select Field */}
                <div className="form-group medication-select">
                    <label>Medication {index + 1}</label>
                    <select
                    value={entry.medication || ''}
                    onChange={(e) =>
                        handleFieldChange('medication', `medication${index + 1}`, e.target.value)
                    }
                    >
                    <option value="">Select Medication</option>
                    {medicationOptions.map((option, idx) => (
                        <option key={idx} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                </div>
                {/* Qty Field */}
                <div className="form-group">
                    <label>Qty</label>
                    <input
                    type="number"
                    min="0.25"
                    step="0.25"
                    value={entry.quantity}
                    onChange={(e) =>
                        handleFieldChange('medication', `quantity${index + 1}`, e.target.value)
                    }
                    />
                </div>
                {/* Time Field */}
                <div className="form-group">
                    <label>Time</label>
                    <input
                    type="time"
                    value={entry.time || ''}
                    onChange={(e) =>
                        handleFieldChange('medication', `time${index + 1}`, e.target.value)
                    }
                    step="900"
                    />
                </div>
                {/* Buttons */}
                <div className="medication-buttons">
                    <button onClick={() => handleAddEntry('medication')}>+</button>
                    <button
                    onClick={() => handleRemoveEntry('medication', index)}
                    disabled={index === 0 || data.medication.length === 1}
                    >
                    -
                    </button>
                </div>
                </div>
            ))}
            </section>

    {/* Footer Section */}
    <footer className="data-footer">
        <div className="button-container">
            <div className="chart-icon-wrapper" onClick={handleViewGraph}>
                <AreaChartOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
            </div>
            <div className="data-icon-wrapper" onClick={handleViewData}>
                <DatabaseOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
            </div>
            <div className="printer-icon-wrapper" onClick={handlePrintClick}>
                <PrinterOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
            </div>
            <div className="summary-icon-wrapper" onClick={fetchWeeklySummaryData}>
                <BarChartOutlined style={{ fontSize: '24px', color: '#1a9cd4' }} />
            </div>
        </div>
    </footer>

        <Modal
            title="Select Print Date Range"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="Print"
            cancelText="Cancel"
            style={{ backgroundColor: '#f2f2f2' }}
        >
            <RangePicker onChange={handleRangeChange} />
        </Modal>

        {/* Weekly Summary Modal */}
        <WeeklySummaryModal
        isVisible={isWeeklySummaryVisible}
        onClose={() => setIsWeeklySummaryVisible(false)}
        summaryData={weeklySummaryData}
        dateRange={dateRange}
        />
    </div>
);
}

export default MainPage;